import {CoursePermission, MemberPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/course/course-list',
        name: 'course-list',
        component: () => import(/* webpackChunkName: "course" */ '~/views/course/course-list/course-list.vue'),
        meta: {
            permission: CoursePermission.ViewCourse
        }
    },
    {
        path: '/course/new-course',
        name: 'new-course',
        component: () => import(/* webpackChunkName: "course" */ '~/views/course/new-course/index.vue'),
        meta: {
            permission: CoursePermission.ViewCourse
        }
    },
    {
        path: '/course/edit-course/:id',
        name: 'edit-course',
        component: () => import(/* webpackChunkName: "course" */ '~/views/course/new-course/index.vue'),
        meta: {
            permission: CoursePermission.ViewCourse
        }
    },
    {
        path: '/course/edit-course/:id/new-lesson',
        name: 'new-lesson',
        component: () => import(/* webpackChunkName: "lesson" */ '~/views/course/new-course/lesson-editor.vue'),
        meta: {
            permission: CoursePermission.EditCourse
        }
    },
    {
        path: '/course/edit-course/:id/edit-lesson/:lesson_id',
        name: 'edit-lesson',
        component: () => import(/* webpackChunkName: "lesson" */ '~/views/course/new-course/lesson-editor.vue'),
        meta: {
            permission: CoursePermission.EditCourse
        }
    },
    {
        path: '/course/edit-course/:id/new-class',
        name: 'new-class',
        component: () => import(/* webpackChunkName: "class" */ '~/views/course/new-course/offline-class-editor.vue'),
        meta: {
            permission: CoursePermission.EditCourse
        }
    },
    {
        path: '/course/edit-course/:id/edit-class/:class_id',
        name: 'edit-class',
        component: () => import(/* webpackChunkName: "class" */ '~/views/course/new-course/offline-class-editor.vue'),
        meta: {
            permission: CoursePermission.EditCourse
        }
    },
    {
        path: '/course/edit-course/review/:id',
        name: 'review-edit-course',
        component: () => import(/* webpackChunkName: "review-edit-course" */ '~/views/course/instructor-course/index.vue'),
        meta: {
            permission: CoursePermission.ViewCourse
        }
    },
    {
        path: '/course/batch/new-class',
        name: 'batch-new-class',
        component: () => import(/* webpackChunkName: "batch-offline-class-editor" */ '~/views/course/new-course/batch-offline-class-editor.vue'),
        meta: {
            permission: CoursePermission.EditCourse
        }
    },
    {
        path: '/course/batch/edit-class',
        name: 'batch-edit-class',
        component: () => import(/* webpackChunkName: "batch-offline-class-editor" */ '~/views/course/new-course/batch-offline-class-editor.vue'),
        meta: {
            permission: CoursePermission.EditCourse
        }
    },
    {
        path: '/course/course-edit-review',
        name: 'course-edit-review',
        component: () => import(/* webpackChunkName: "course-edit-review" */ '~/views/course/course-edit-review.vue'),
        meta: {
            permission: CoursePermission.ViewCourse
        }
    },
    {
        path: '/adminv/course/series',
        name: 'course-series',
        meta: {
            permission: CoursePermission.CourseSeries
        }
    },
    {
        path: '/course/tags',
        name: 'course-tags',
        component: () => import(/* webpackChunkName: "course-edit-review" */ '~/views/course/course-tags.vue'),
        meta: {
            permission: CoursePermission.CourseTag
        }
    },
    {
        path: '/stream-archive',
        name: 'stream-archive',
        component: () => import(/* webpackChunkName: "stream-archive" */ '~/views/course/stream-archive.vue'),
        meta: {
            permission: MemberPermission.EnrollmentList
        }
    },
    {
        path: '/course/enrollment-list',
        name: 'enrollment-list',
        component: () => import(/* webpackChunkName: "enrollment-list" */ '~/views/course/course-list/enrollment-list.vue'),
        meta: {
            permission: MemberPermission.EnrollmentList,
            classes: 'v-application--grey'
        }
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: () => import(/* webpackChunkName: "reviews" */ '~/views/course/reviews/review-manager.vue'),
        meta: {
            permission: CoursePermission.CourseReviews
        }
    }
]

export default routes
